import React from "react";
import { useEffect } from "react";

function AllCheckAll({ toggleAllCharacters, checkedList }) {
  const [checkedClass, setCheckedClass] = React.useState("");

  function isEveryChecked(group) {
    return checkedList[group].every((kana) => kana.select);
  }

  function isEveryGroupChecked() {
    if (
      isEveryChecked("group1") &&
      isEveryChecked("group2") &&
      isEveryChecked("group3")
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (isEveryGroupChecked()) {
      setCheckedClass("check");
    } else {
      setCheckedClass("");
    }
  });

  return (
    <div>
      <label className={`select-box ${checkedClass}`} htmlFor="all-main">
        <input onChange={toggleAllCharacters} type="checkbox" id="all-main" />
        All Kana
      </label>
    </div>
  );
}

export default AllCheckAll;
