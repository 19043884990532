function isArrayFlat(array) {
  return array.every(function (elem) {
    return !Array.isArray(elem);
  });
}

function flattenArrayOneLevel(array) {
  let outputArray = [];

  array.forEach(function (elem) {
    outputArray = outputArray.concat(elem);
  });

  return outputArray;
}

function flattenArray(array) {
  let outputArray = array;
  while (!isArrayFlat(outputArray)) {
    outputArray = flattenArrayOneLevel(outputArray);
  }
  return outputArray;
}

export default flattenArray;
