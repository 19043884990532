import { toHiragana, toKatakana, isKana } from "wanakana";

const alternateAnswerItems = {
  ん: ["n", "nn"],
  ぢゃ: ["zya", "ja"],
  ぢゅ: ["zyu", "ju"],
  ぢょ: ["zyo", "jo"],
  ヂャ: ["zya", "ja"],
  ヂュ: ["zyu", "ju"],
  ヂョ: ["zyo", "jo"],
};

function answerChecker(kanaFromCard, romajiFromUser) {
  if (isKana(romajiFromUser)) {
    return false;
  }

  const alternateAnswers = alternateAnswerItems[kanaFromCard] || [];

  return (
    alternateAnswers.includes(romajiFromUser.toLowerCase()) ||
    kanaFromCard === toHiragana(romajiFromUser) ||
    kanaFromCard === toKatakana(romajiFromUser)
  );
}

export default answerChecker;
