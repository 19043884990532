import flattenArray from "./flattenArray";

function calculateCorrectness(data) {
  let numberVariable = 0;
  flattenArray(data).forEach((kana) => {
    if (kana.incorrectNum === 0 && kana.correct) {
      numberVariable += 1;
    }
  });
  return numberVariable;
}

export default calculateCorrectness;
