import React, { useEffect, useRef } from "react";
import Cards from "./Cards";

function InstructionsText() {
  return (
    <div>
      <h2>Type Romaji for the Kana You Know</h2>
      <ul>
        <li>Type your answer in romaji in the card's text field</li>
        <li>Press ENTER to submit</li>
        <li>Repeat for as many cards as you can</li>
        <li>You can try as many times as you want</li>
        <li>When you're done press the "Finish Quiz" button at the bottom</li>
      </ul>
    </div>
  );
}

function QuizPage({ nextPage, checkedList, fontStyle }) {
  const [quizCardsCorrect, setQuizCardsCorrect] = React.useState(false);
  const nextButton = useRef();

  useEffect(() => {
    if (quizCardsCorrect) {
      nextButton.current.focus();
    }
  }, [quizCardsCorrect]);

  return (
    <div className="quiz-page">
      <InstructionsText />
      <Cards
        setQuizCardsCorrect={setQuizCardsCorrect}
        checkedList={checkedList}
        fontStyle={fontStyle}
      />
      <div>
        <button className="button-styling" onClick={nextPage} ref={nextButton}>
          Finish Quiz
        </button>
      </div>
    </div>
  );
}

export default QuizPage;
