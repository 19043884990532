import React from "react";

function ToggleAllButton({ category, toggleCategory, checkedClass }) {
  return (
    <label
      className={`select-box ${checkedClass}`}
      htmlFor={category}
      key={category}
    >
      <input type="checkbox" id={category} onChange={toggleCategory} />
      All {category} Kana
    </label>
  );
}

export default ToggleAllButton;
