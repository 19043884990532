import React, { useEffect } from "react";
import calculateCorrectness from "./calculateCorrectness";
import flattenArray from "./flattenArray";

function ResultsTableSection({ group, kanaRow, fontStyle }) {
  return (
    <React.Fragment>
      <tr className="kana-header">
        {group.map((kana, index) => {
          if (kana.row === kanaRow) {
            return (
              <th key={index}>
                <span style={{ fontFamily: fontStyle }}>{kana.name}</span>
              </th>
            );
          }
        })}
      </tr>
      <tr className="kana-details">
        {group.map((kana, index) => {
          if (kana.row === kanaRow) {
            const incorrectNumSwitch =
              kana.incorrectNum === 0 ? "N/A" : `${kana.incorrectNum}`;
            const correctOrIncorrect =
              kana.correct && kana.incorrectNum === 0
                ? "◯"
                : `X: ${incorrectNumSwitch}`;
            return <td key={index}>{correctOrIncorrect}</td>;
          }
        })}
      </tr>
      <tr className="kana-study">
        {group.map((kana, index) => {
          const threeOrFive = kana.category === "combination" ? "3" : "5";
          if (kana.row === kanaRow && kana.head) {
            return (
              <td colSpan={threeOrFive} key={index}>
                <a href={kana.link}>↪ Study again</a>
              </td>
            );
          }
        })}
      </tr>
    </React.Fragment>
  );
}

function KanaRowResults({ group, findQuizPercentage, fontStyle }) {
  const [groupData, setGroupData] = React.useState([]);

  useEffect(() => {
    setGroupData(group);
  }, []);

  const headerName = groupData.map((kana) => kana.category)[0];

  const groupLength = groupData.length;
  const groupNumCorrect = calculateCorrectness(groupData);

  const quizPercentage = findQuizPercentage(groupNumCorrect, groupLength);

  return (
    <div className="table-wrap">
      <div className="table-section-top">
        <h2>{headerName} Kana</h2>
        <p>
          {groupNumCorrect}/{groupLength} ({quizPercentage}%)
        </p>
      </div>
      <table>
        <tbody>
          {groupData.map((kana, index) => {
            if (kana.head) {
              return (
                <ResultsTableSection
                  key={index}
                  group={groupData}
                  kanaRow={kana.row}
                  fontStyle={fontStyle}
                />
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}

function ResultsPage({ nextPage, checkedList, retryQuiz, fontStyle }) {
  const [resultsData, setResultsData] = React.useState([]);

  const flatResultsLength = flattenArray(resultsData).length;
  const resultsNumCorrect = calculateCorrectness(resultsData);

  function findQuizPercentage(numCorrect, numLength) {
    return ((numCorrect / numLength) * 100).toFixed(2);
  }

  useEffect(() => {
    setResultsData(
      Object.keys(checkedList)
        .map((group) => {
          return checkedList[group].filter((kana) => kana.select === true);
        })
        .filter((arr) => arr[0] !== undefined)
    );
  }, []);

  return (
    <div className="results">
      <h2>View Your Results</h2>
      <h3>
        Overall Correct: {resultsNumCorrect}/{flatResultsLength} (
        {findQuizPercentage(resultsNumCorrect, flatResultsLength)}%)
      </h3>

      <div className="incorrect-details">
        {resultsData.map((group, index) => {
          return (
            <KanaRowResults
              key={index}
              group={group}
              findQuizPercentage={findQuizPercentage}
              fontStyle={fontStyle}
            />
          );
        })}
      </div>

      <div className="text">
        <p>
          {`Feeling like you can recall all the kana? Your next step in
          Japanese literacy is kanji. We built a program that uses mnemonics,
          spaced repetition, and more to teach Japanese learners 2,000+ kanji
          and 6,000+ vocabulary words. You can `}
          <a href="https://www.wanikani.com/">
            try the first three levels of WaniKani for free
          </a>
          {`, no credit card required.`}
        </p>

        <p>Or study kana again!</p>
      </div>

      <button className="restart restart-button-styling" onClick={nextPage}>
        Home
      </button>
      <button className="restart restart-button-styling" onClick={retryQuiz}>
        Quiz Again
      </button>
    </div>
  );
}

export default ResultsPage;
