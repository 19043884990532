import React from "react";
import { useEffect } from "react";
import Box from "./Box";
import ToggleAllButton from "./ToggleAllButton";

function CheckboxCategory({
  category,
  group,
  toggleOneBox,
  list,
  toggleCategory,
  columnStyle,
  fontStyle,
}) {
  const [checkedClass, setCheckedClass] = React.useState("");

  useEffect(() => {
    if (list.every((kana) => kana.select)) {
      setCheckedClass("check");
    } else {
      setCheckedClass("");
    }
  });

  return (
    <div>
      <h2>{category} Kana</h2>
      <ToggleAllButton
        checkedClass={checkedClass}
        toggleCategory={() => {
          toggleCategory(group);
        }}
        category={category}
      />

      <div className="checkboxes" style={columnStyle}>
        {list.map((item, index) => {
          return (
            <Box
              key={index}
              item={item}
              toggleOneBox={() => {
                toggleOneBox(item.name, group);
              }}
              fontStyle={fontStyle}
            />
          );
        })}
      </div>
    </div>
  );
}

export default CheckboxCategory;
