import React from "react";
import CheckboxCategory from "./CheckboxCategory";
import hiragana from "./hiragana";
import katakana from "./katakana";
import cloneDeep from "lodash/cloneDeep";

const oneColumnStyle = {
  gridTemplateColumns: "1fr",
};

const twoColumnStyle = {
  gridTemplateColumns: "1fr 1fr",
};

function StartPage({
  nextPage,
  AllCheckAll,
  toggleAllCharacters,
  toggleOneBox,
  checkedList,
  toggleCategory,
  setCheckedList,
  setFontStyle,
  fontStyle,
}) {
  const [kanaCheckedState, setKanaCheckedState] = React.useState({
    hCheck: true,
    kCheck: false,
  });

  const hiraganaChecked = kanaCheckedState.hCheck ? "check" : "";
  const katakanaChecked = kanaCheckedState.kCheck ? "check" : "";

  const fontList = [
    { value: "Noto Sans JP", label: "Noto Sans JP" },
    { value: "Noto Serif JP", label: "Noto Serif JP" },
  ];

  function handleFontChange(e) {
    setFontStyle(e.target.value);
  }

  return (
    <div className="start">
      <h1>Tofugu’s Learn Kana Quiz</h1>
      <div className="text">
        <p>
          This app is a companion to Tofugu’s Learn Hiragana Guide and Learn
          Katakana Guide. Use it to practice hiragana and katakana. If you
          haven’t learned kana yet, check out{" "}
          <a href="https://www.tofugu.com/japanese/learn-hiragana/">
            the hiragana guide
          </a>{" "}
          or{" "}
          <a href="https://www.tofugu.com/japanese/learn-katakana/">
            the katakana guide
          </a>
          , then come back here.
        </p>
      </div>

      <div className="font-choice-dropdown">
        <label for="font">Choose kana font: </label>
        <select name="font" id="font" onChange={handleFontChange}>
          {fontList.map((font) => {
            const isSelectedFont = font.value === fontStyle;
            return (
              <option value={font.value} selected={isSelectedFont}>
                {font.label}
              </option>
            );
          })}
        </select>
      </div>

      <div className="kana-button-container">
        <label
          className={`select-box ${hiraganaChecked}`}
          htmlFor="practice-hiragana"
        >
          <input
            onChange={() => {
              setCheckedList(cloneDeep(hiragana));
              setKanaCheckedState({ hCheck: true, kCheck: false });
            }}
            type="checkbox"
            id="practice-hiragana"
            checked={kanaCheckedState.hCheck}
          />
          Practice Hiragana
        </label>
        <label
          className={`select-box ${katakanaChecked}`}
          htmlFor="practice-katakana"
        >
          <input
            onChange={() => {
              setCheckedList(cloneDeep(katakana));
              setKanaCheckedState({ hCheck: false, kCheck: true });
            }}
            type="checkbox"
            id="practice-katakana"
            checked={kanaCheckedState.kCheck}
          />
          Practice Katakana
        </label>
      </div>
      <AllCheckAll
        checkedList={checkedList}
        toggleAllCharacters={() => {
          toggleAllCharacters();
        }}
      />
      <div className="checkbox-container">
        <CheckboxCategory
          category="Main"
          group="group1"
          key="group1"
          columnStyle={twoColumnStyle}
          toggleOneBox={toggleOneBox}
          list={checkedList.group1.filter((elem) => elem.head)}
          toggleCategory={toggleCategory}
          fontStyle={fontStyle}
        />
        <CheckboxCategory
          category="Dakuten"
          group="group2"
          key="group2"
          columnStyle={oneColumnStyle}
          toggleOneBox={toggleOneBox}
          list={checkedList.group2.filter((elem) => elem.head)}
          toggleCategory={toggleCategory}
          fontStyle={fontStyle}
        />
        <CheckboxCategory
          category="Combination"
          group="group3"
          key="group3"
          columnStyle={twoColumnStyle}
          toggleOneBox={toggleOneBox}
          list={checkedList.group3.filter((elem) => elem.head)}
          toggleCategory={toggleCategory}
          fontStyle={fontStyle}
        />
      </div>
      <button onClick={nextPage} className="button-styling">
        Start Quiz!
      </button>
    </div>
  );
}

export default StartPage;
