function moveToNext(data, startIndex) {
  if (data.length === 1) {
    return 0;
  }

  let counter = 0;
  if (data[startIndex] === undefined) {
    startIndex = 0;
  }
  while (data[startIndex].correct === true) {
    startIndex += 1;
    counter += 1;
    if (data[startIndex] === undefined) {
      startIndex = 0;
    } else if (counter >= data.length) {
      return null;
    }
  }
  return startIndex;
}

export default moveToNext;
