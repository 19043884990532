import React, { useEffect, useRef } from "react";
import answerChecker from "./answerChecker";

function Card({
  index,
  text,
  focused,
  updateCard,
  focusHandler,
  correct,
  endOfTheLine,
  setEndOfTheLine,
  fontStyle,
}) {
  const [correctClass, setCorrectClass] = React.useState("");
  const [placeholder, setPlaceholder] = React.useState("");
  const textInput = useRef();

  useEffect(() => {
    if (focused) {
      textInput.current.focus();
      if (endOfTheLine) {
        window.scrollBy(0, -30);
        setEndOfTheLine(false);
      }
    }
  }, [focused]);

  function handleSubmit(e) {
    e.preventDefault();
    if (
      !textInput.current ||
      !textInput.current.value ||
      textInput.current.value.trim().length === 0
    ) {
      return;
    }
    const romajiFromUser = textInput.current.value;
    const result = answerChecker(text, romajiFromUser);
    if (result) {
      setCorrectClass("correct");
    } else {
      setCorrectClass("incorrect");
      setPlaceholder(romajiFromUser);
      textInput.current.value = "";
    }
    updateCard(result, index);
  }

  const cardClasses = focused ? "card focused-card" : "card";

  return (
    <label
      className={`${cardClasses} ${correctClass}`}
      data-testid="label-test"
    >
      <div className="card__text">
        <span style={{ fontFamily: fontStyle }}>{text}</span>
      </div>
      <form
        className="card__form"
        data-testid="cardForm"
        onSubmit={handleSubmit}
        onBlur={handleSubmit}
      >
        <input
          type="text"
          ref={textInput}
          onFocus={focusHandler}
          disabled={correct}
          className={`card__field`}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
          placeholder={placeholder}
        />
      </form>
    </label>
  );
}

export default Card;
