import React from "react";
import "./App.scss";
import AllCheckAll from "./AllCheckAll";

import StartPage from "./StartPage";
import QuizPage from "./QuizPage";
import ResultsPage from "./ResultsPage";
import cloneDeep from "lodash/cloneDeep";
import hiragana from "./hiragana";
import flattenArray from "./flattenArray";

function App() {
  const [checkedList, setCheckedList] = React.useState(cloneDeep(hiragana));
  const [fontStyle, setFontStyle] = React.useState("Noto Sans JP");

  const toggleAllCharacters = () => {
    const copy = {};

    const areSomeBoxOff = Object.keys(checkedList).some((group) => {
      return checkedList[group].some((elem) => elem.select === false);
    });

    if (areSomeBoxOff) {
      Object.keys(checkedList).forEach((group) => {
        copy[group] = checkedList[group].map((elem) => ({
          ...elem,
          select: true,
        }));
      });
    } else {
      Object.keys(checkedList).forEach((group) => {
        copy[group] = checkedList[group].map((elem) => ({
          ...elem,
          select: false,
        }));
      });
    }

    setCheckedList(copy);

    // see what is checked
    // set boxes to opposite
  };

  const toggleCategory = (group) => {
    // check what category it is: DONE!
    // get all those checked box values
    const copy = { ...checkedList };
    const spreadGroup = [...checkedList[group]];

    // are some not checked?
    const areSomeCheckBoxOff = spreadGroup.some(
      (elem) => elem.select === false
    );

    // yes? then turn them all on

    if (areSomeCheckBoxOff) {
      spreadGroup.map((elem) => (elem.select = true));
    } else {
      spreadGroup.map((elem) => (elem.select = false));
    }

    // change copy version of group to new spreadGroup copy, then set copy to state

    copy[group] = spreadGroup;
    setCheckedList(copy);
  };

  const toggleOneBox = (character, group) => {
    const copy = { ...checkedList };
    const groupSpread = [...checkedList[group]];

    groupSpread.forEach((kana) => {
      if (kana.row === character) {
        kana.select = !kana.select;
      }
    });

    copy[group] = groupSpread;
    setCheckedList(copy);
  };

  const [pageNum, setPageNum] = React.useState(1);

  function nextPage() {
    let youShallPass = false;
    Object.keys(checkedList).forEach((group) => {
      if (checkedList[group].some((kana) => kana.select === true)) {
        youShallPass = true;
      }
    });

    const allSelect = [];
    Object.keys(checkedList).forEach((group) => {
      allSelect.push(checkedList[group].filter((kana) => kana.select === true));
    });

    if (!youShallPass) {
      alert("Please make a selection to continue.");
    } else if (pageNum < 3 && youShallPass) {
      if (
        flattenArray(allSelect).some((item) => item.correct === false) &&
        pageNum === 2
      ) {
        const promptWindow = window.confirm(
          "You still have a few cards that need work. Are you sure you want to finish the quiz now?"
        );
        if (!promptWindow) {
          return;
        }
      }
      window.scrollTo(0, 0);
      setPageNum(pageNum + 1);
    } else {
      window.scrollTo(0, 0);
      setPageNum(1);
      setCheckedList(cloneDeep(hiragana));
    }
  }

  function resetCheckedList() {
    const copy = {};

    Object.keys(checkedList).forEach((group) => {
      copy[group] = checkedList[group].map((elem) => ({
        ...elem,
        correct: false,
        incorrectNum: 0,
        lastIncorrectVal: null,
      }));
    });

    setCheckedList(copy);
  }

  function retryQuiz() {
    resetCheckedList();
    setPageNum(2);
  }

  const page1 =
    pageNum === 1 ? (
      <StartPage
        nextPage={nextPage}
        AllCheckAll={AllCheckAll}
        toggleAllCharacters={toggleAllCharacters}
        toggleOneBox={toggleOneBox}
        checkedList={checkedList}
        toggleCategory={toggleCategory}
        setCheckedList={setCheckedList}
        setFontStyle={setFontStyle}
        fontStyle={fontStyle}
      />
    ) : (
      ""
    );
  const page2 =
    pageNum === 2 ? (
      <QuizPage
        nextPage={nextPage}
        checkedList={checkedList}
        fontStyle={fontStyle}
      />
    ) : (
      ""
    );
  const page3 =
    pageNum === 3 ? (
      <ResultsPage
        nextPage={nextPage}
        retryQuiz={retryQuiz}
        checkedList={checkedList}
        fontStyle={fontStyle}
      />
    ) : (
      ""
    );

  return (
    <div className="App">
      {page1}
      {page2}
      {page3}
    </div>
  );
}

export default App;
