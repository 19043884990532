import React, { useEffect, useState } from "react";
import Card from "./Card";
import moveToNext from "./moveToNext";
import flattenArray from "./flattenArray";

function Cards({ setQuizCardsCorrect, checkedList, fontStyle }) {
  const [focus, setFocus] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [endOfTheLine, setEndOfTheLine] = useState(false);

  useEffect(() => {
    setCardData(
      shuffleCards(
        flattenArray(
          Object.keys(checkedList).map((group) => {
            return checkedList[group].filter((kana) => kana.select === true);
          })
        )
      )
    );
  }, []);

  const shuffleCards = (array) => {
    const workingCopy = [...array];

    const shuffledArray = array.map(() => {
      const arraySize = workingCopy.length;
      const randomNumber = Math.floor(Math.random() * arraySize);

      return workingCopy.splice(randomNumber, 1)[0];
    });
    return shuffledArray;
  };

  function findFinalIncorrectCard(copyOfCardData) {
    const incorrectCards = copyOfCardData.filter((card) => !card.correct);
    return incorrectCards[incorrectCards.length - 1];
  }

  const updateCard = (correct, index) => {
    const copyOfCardData = [...cardData];

    copyOfCardData[index].correct = correct;
    if (!correct) {
      copyOfCardData[index].incorrectNum =
        copyOfCardData[index].incorrectNum + 1;
    }
    setCardData(copyOfCardData);
    setFocus(moveToNext(copyOfCardData, index + 1));

    const finalIncorrectCard = findFinalIncorrectCard(copyOfCardData);

    if (index >= copyOfCardData.indexOf(finalIncorrectCard)) {
      setEndOfTheLine(true);
    }
    if (copyOfCardData.every((item) => item.correct)) {
      setQuizCardsCorrect(true);
    }
  };

  return (
    <>
      <div className="quiz">
        {cardData.map((item, index) => {
          return (
            <Card
              key={item.name}
              index={index}
              text={item.name}
              focused={index === focus}
              correct={item.correct}
              updateCard={updateCard}
              endOfTheLine={endOfTheLine}
              setEndOfTheLine={setEndOfTheLine}
              focusHandler={() => {
                setFocus(index);
              }}
              fontStyle={fontStyle}
            />
          );
        })}
      </div>
    </>
  );
}

export default Cards;
